<template>
  <AgGridVue
    style="width: 100%; height: 100%"
    class="ag-theme-alpine data-table__content"
    :columnDefs="columnDefs"
    :rowData="rowData"
    @rowClicked="rowClicked"
    @gridReady="onGridReady"
  >
  </AgGridVue>
</template>

<script>
import store from "@/store";
import { AgGridVue } from 'ag-grid-vue3';
import { useToast } from 'vue-toastification';
import { LocalDate, DateTimeFormatter } from "@js-joda/core";

export default {
  data() {
    return {
      gridApi: null,
      columnApi: null,
      columnDefs: [],
      rowData: [],
      selectedOrder: null
    }
  },

  props: {
    serviceId: { type: Number }
  },

  methods: {
    async fetchData() {

      this.loading = true;

      this.selectedDate = this.$route.query.date || LocalDate.now().format(DateTimeFormatter.ofPattern("yyyy-MM-dd"));

      try {
        const response = await store.state.apiPrivate.client.endpoints.statementsServicesOrders.get(this.serviceId);
        const data = await response?.data?.data;

        this.columnDefs = [
          { headerName: "Order Id", field: "orderId", pinned: 'left' },
          { headerName: 'Ajusted Total', field: 'adjustedTotal' },
          { headerName: 'Gross Revenue', field: 'grossRevenue' },
          { headerName: 'Revenue', field: 'revenue' },
          { headerName: "Adjustment Cash", field: "adjustmentCash", },
          { headerName: "Cancellations", field: "cancellationsTotal", },
          { headerName: "customer Cancellations", field: "customerCancellations", },
          { headerName: "Donations", field: "charityDonations", },
          { headerName: "Refunds", field: "refunds", },
          { headerName: "Donations", field: "charityDonations", },
          { headerName: "Donations", field: "charityDonations", },
          { headerName: "Service Charge", field: "serviceCharge", },
        ];

        this.rowData = data;

      } catch (error) {
        useToast().error(error);
      }

      this.loading = false;

    },

    onGridReady(params) {
      // get the grid and column api
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.fetchData();
    },

    rowClicked(data) {
      this.selectedOrder = data;
    }

  },

  components: {
    AgGridVue,
  },
};
</script>