<template>
  <div class="statement__header">
    <input
      class="detail-control"
      v-model="date"
      type="date"
      :disabled="ifDisabled"
    />
    <div
      class="input-wrapper"
      v-if="isServiceStatement"
    >
      <input
        type="checkbox"
        name="includeOrders"
        id="includeOrders"
        v-model="ifIncludeOrders"
        :disabled="ifDisabled"
      />
      <label for="includeOrders">Include Order Details</label>
    </div>
    <ContainedButton
      text="Update"
      @click="update"
      :disabled="ifDisabled"
    />
  </div>
</template>

<script>
import ContainedButton from "@/components/Buttons/ContainedButton.vue";
import { LocalDate, DateTimeFormatter } from "@js-joda/core";

export default {
  data() {
    return {
      ifIncludeOrders: this.isServiceStatement ? this.$route.query.orders : false,
      date: this.$route.query.date || LocalDate.now().format(DateTimeFormatter.ofPattern("yyyy-MM-dd")),
    }
  },
  props: {
    isServiceStatement: {
      type: Boolean,
      default: false
    },
    ifDisabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    update() {

      if (!this.ifIncludeOrders || !this.isServiceStatement) {
        this.$router.replace({ query: { date: this.date } });
      } else {
        this.$router.replace({ query: { date: this.date, orders: this.ifIncludeOrders } });
      }
    }
  },
  components: {
    ContainedButton,
  },

}
</script>

<style lang="scss">
.statement__header {
  @include flex-initial($alignItems: center, $gap: 1rem);
  margin-bottom: 1.5rem;

  .input-wrapper {
    @include flex-initial($alignItems: center);

    label {
      cursor: pointer;
    }
  }
}
</style>